import {Formik, Form, Field, ErrorMessage} from "formik";
import React, {useEffect, useState} from "react";
import {
    IGetFilterPublicTendersCareers,
    IGetFilterPublicTendersExaminingBoards,
    IGetFilterPublicTendersInstitutions,
    IGetFilterPublicTendersOccupations,
    IGetPublicTenders,
    IPublicTenderResponse,
} from "../../../ts/interfaces/PublicTender/interface";
import styles from "../../../styles/PublicTender/styles.module.scss";
import Button from "../../../components/Button/Button";

import {ReactComponent as Download} from "../../../assets/icons/download.svg";
import {ReactComponent as Pencil} from "../../../assets/icons/pencil.svg";
import {ReactComponent as Trash} from "../../../assets/icons/trash.svg";

import {BarLoader} from "react-spinners";
import {useNavigate} from "react-router-dom";
import {
    deletePublicTender, filterCareers,
    filterExaminingBoards,
    getPublicTenders,
    filterInstitutions, filterOccupations,
    serializeArray, getStates,
} from "../../../services/services";
import Pagination from "../../../components/Pagination/Pagination";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import {useMutation, useQuery} from "react-query";
import {toast} from "react-toastify";
import {queryClient} from "../../../services/queryClient";
import {getToken} from "../../../services/auth";
import Select from "../../../components/Select/Select";
import Select2 from "react-select";
import {ReactComponent as File} from "../../../assets/icons/file.svg";
import {stylesSelect} from "../../../helper/stylesSelect";
import {IStateResponse} from "../../../ts/interfaces/state/interface";

const PublicTender: React.FC = () => {

    const [filter, setFilter] = useState<IGetPublicTenders>({
        name: "",
        year: "",
        level: "",
        state_id: "",
        institution_id: "",
        examining_board_id: "",
        occupation_id: "",
        career_id: "",
        page: 1,
        limit: 50,
    });

    const url = new URL(window.location.toString())

    const [open, setOpen] = useState(false);
    const [id, setId] = useState<number | null>(null);
    const [selectFilters, setSelectFilters] = useState({
        year: "",
        state_id: url.searchParams.get("state") ?? "",
        institution_id: url.searchParams.get("institution") ?? "",
        examining_board_id: url.searchParams.get("examiningBoard") ?? "",
        occupation_id: url.searchParams.get("occupation") ?? "",
        level: "",
        career_id: url.searchParams.get("career") ?? "",
    });

    useEffect(() => {

        if (url.searchParams.get("career")) {
            const careerId = url.searchParams.get("career");
            setFilter((prev) => ({
                ...prev,
                page: 1,
                limit: 50,
                career_id: careerId ?? "",
            }));
        }

        if (url.searchParams.get("institution")) {
            const institutionId = url.searchParams.get("institution");
            setFilter((prev) => ({
                ...prev,
                page: 1,
                limit: 50,
                institution_id: institutionId ?? "",
            }));
        }

        if (url.searchParams.get("examiningBoard")) {
            const examiningBoardId = url.searchParams.get("examiningBoard");
            setFilter((prev) => ({
                ...prev,
                page: 1,
                limit: 50,
                examining_board_id: examiningBoardId ?? "",
            }));
        }

        if (url.searchParams.get("state")) {
            const stateId = url.searchParams.get("state");
            setFilter((prev) => ({
                ...prev,
                page: 1,
                limit: 50,
                state_id: stateId ?? "",
            }));
        }

        if (url.searchParams.get("occupation")) {
            const occupationId = url.searchParams.get("occupation");
            setFilter((prev) => ({
                ...prev,
                page: 1,
                limit: 50,
                occupation_id: occupationId ?? "",
            }));
        }

    }, []);

    const navigate = useNavigate();

    const {data, isLoading} = useQuery<IPublicTenderResponse>(
        ["public-tenders", filter],
        () => getPublicTenders(filter),
        {
            staleTime: Infinity,
        }
    );

    const handleDownloadCSV = () => {
        let link = document.getElementById("download_csv");
        link?.click();
    };

    const mutateDeleteInstitution = useMutation(
        () => {
            return deletePublicTender(id as number);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(["public-tenders", filter]);
                toast.success('Deletado com sucesso');
                setOpen(false);
            },
            onError: () => {
                toast.error("Erro ao deletar o concurso");
            },
        }
    );

    const getYears = () => {
        let years = [];
        let actualYear = new Date().getFullYear();
        for (let i = actualYear; i >= actualYear - 20; i--) {
            years.push(i);
        }

        return years
    };

    const {data: careers} = useQuery<IGetFilterPublicTendersCareers>(
        "filter-careers",
        () => filterCareers()
    );

    const {data: examiningBoards} = useQuery<IGetFilterPublicTendersExaminingBoards>('filter-examining-boards', () => filterExaminingBoards())

    const {data: institutions} = useQuery<IGetFilterPublicTendersInstitutions>('filter-institutions', () => filterInstitutions())

    const {data: occupations} = useQuery<IGetFilterPublicTendersOccupations>('filter-occupations', () => filterOccupations())

    const {data: states} = useQuery<IStateResponse>(['states', filter], () => getStates({state: null}))

    return (
        <div className={styles.container}>
            <h1>Concursos</h1>

            <Formik
                initialValues={{
                    name: "",
                    level: "",
                }}
                onSubmit={(values) => {
                    setFilter({
                        ...filter,
                        name: values.name,
                        year: selectFilters.year,
                        level: selectFilters.level,
                        institution_id: selectFilters.institution_id,
                        examining_board_id: selectFilters.examining_board_id,
                        career_id: selectFilters.career_id,
                        occupation_id: selectFilters.occupation_id,
                        state_id: selectFilters.state_id,
                        page: 1,
                    });
                }}
            >
                <Form className={styles.searchProducts}>
                    <div className={styles.alignInput}>
                        <Field
                            name="name"
                            type="text"
                            placeholder="Pesquisar por nome"
                            className={styles.input}
                            disabled={isLoading}
                        />
                        <ErrorMessage
                            name="name"
                            component="div"
                            className={styles.error}
                        />

                        <div className={styles.groupInput}>
                            <Select2
                                isClearable={true}
                                styles={stylesSelect}
                                options={getYears().map((year) => ({
                                    value: year,
                                    label: year,
                                }))}
                                onChange={(selectedOption) => {
                                    const selected = selectedOption?.value || "";
                                    setSelectFilters((prev) => ({...prev, year: selected.toString()}));

                                }}
                                placeholder="Pesquisar por ano"
                            />
                        </div>

                        <div className={styles.groupInput}>
                            <Select2
                                isClearable={true}
                                styles={stylesSelect}
                                options={[
                                    {value: "alfabetizado", label: "Alfabetizado"},
                                    {value: "fundamental_incompleto", label: "fundamental incompleto"},
                                    {value: "fundamental", label: "Fundamental"},
                                    {value: "médio", label: "Médio"},
                                    {value: "superior", label: "Superior"},
                                    {value: "técnico", label: "Técnico"},
                                ]}
                                onChange={(selectedOption) => {
                                    const selected = selectedOption?.value || "";
                                    setSelectFilters((prev) => ({...prev, level: selected.toString()}));

                                }}
                                placeholder="Pesquisar por nível"
                            />
                        </div>

                        <div className={styles.groupInput}>
                            {institutions && institutions?.data.institutions && (
                                <Select2
                                    isClearable={true}
                                    defaultValue={{
                                        value: Number(selectFilters.institution_id),
                                        label: institutions?.data?.institutions.find((i) => i.id === Number(selectFilters.institution_id))?.name || "Pesquisar por instituição"
                                    }}
                                    styles={stylesSelect}
                                    options={institutions?.data.institutions.map((institution) => ({
                                        value: institution.id,
                                        label: institution.name,
                                    }))}
                                    onChange={(selectedOption) => {
                                        const selected = selectedOption?.value || "";
                                        setSelectFilters((prev) => ({...prev, institution_id: selected.toString()}));

                                    }}
                                    placeholder="Pesquisar por instituição"
                                />
                            )}
                        </div>


                        <div className={styles.groupInput}>
                            {examiningBoards && examiningBoards?.data.examiningBoards && (
                                <Select2
                                    isClearable={true}
                                    defaultValue={{
                                        value: Number(selectFilters.examining_board_id),
                                        label: examiningBoards?.data?.examiningBoards.find((i) => i.id === Number(selectFilters.examining_board_id))?.name || "Pesquisar por banca"
                                    }}
                                    styles={stylesSelect}
                                    options={examiningBoards?.data.examiningBoards.map((examiningBoard) => ({
                                        value: examiningBoard.id,
                                        label: examiningBoard.name,
                                    }))}
                                    onChange={(selectedOption) => {
                                        const selected = selectedOption?.value || "";
                                        setSelectFilters((prev) => ({
                                            ...prev,
                                            examining_board_id: selected.toString()
                                        }));

                                    }}
                                    placeholder="Pesquisar por banca"
                                />
                            )}
                        </div>

                        <div className={styles.groupInput}>
                            {occupations && occupations?.data.occupations && (
                                <Select2
                                    isClearable={true}
                                    defaultValue={{
                                        value: Number(selectFilters.occupation_id),
                                        label: occupations?.data?.occupations.find((i) => i.id === Number(selectFilters.occupation_id))?.name || "Pesquisar por cargo"
                                    }}
                                    styles={stylesSelect}
                                    options={occupations?.data.occupations.map((occupation) => ({
                                        value: occupation.id,
                                        label: occupation.name,
                                    }))}
                                    onChange={(selectedOption) => {
                                        const selected = selectedOption?.value || "";
                                        setSelectFilters((prev) => ({
                                            ...prev,
                                            occupation_id: selected.toString()
                                        }));

                                    }}
                                    placeholder="Pesquisar por cargo"
                                />
                            )}
                        </div>

                        <div className={styles.groupInput}>
                            {careers && careers.data && careers.data.careers && (
                                <Select2
                                    isClearable={true}
                                    defaultValue={{
                                        value: Number(selectFilters.career_id),
                                        label: careers?.data?.careers.find((c) => c.id === Number(selectFilters.career_id))?.name || "Adicione uma carreira..."
                                    }}
                                    styles={stylesSelect}
                                    options={careers?.data.careers.map(
                                        (career) => ({
                                            value: career.id,
                                            label: career.name,
                                        })
                                    )}
                                    onChange={(selectedOption) => {
                                        const selectedCareerId = selectedOption?.value || "";
                                        setSelectFilters((prev) => ({...prev, career_id: selectedCareerId.toString()}));

                                    }}
                                    placeholder="Pesquisar por carreira"
                                />
                            )}
                        </div>

                        <div className={styles.groupInput}>
                            {states && states.data && states.data.states && (
                                <Select2
                                    isClearable={true}
                                    defaultValue={{
                                        value: Number(selectFilters.state_id),
                                        label: states?.data?.states.find((c) => c.id === Number(selectFilters.state_id))?.name || "Pesquisar por estado"
                                    }}
                                    styles={stylesSelect}
                                    options={states?.data.states.map(
                                        (state) => ({
                                            value: state.id,
                                            label: state.name + (state.name !== state.acronym ? ' - ' + state.acronym : ''),
                                        })
                                    )}
                                    onChange={(selectedOption) => {
                                        const selectedStateId = selectedOption?.value || "";
                                        setSelectFilters((prev) => ({...prev, state_id: selectedStateId.toString()}));

                                    }}
                                    placeholder="Pesquisar por estado"
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.alignButtonSearch}>
                        <Button
                            type="submit"
                            width="115px"
                            height="39px"
                            theme="primary"
                            fontSize="14px"
                            disabled={isLoading}
                        >
                            Pesquisar
                        </Button>
                    </div>
                </Form>
            </Formik>

            <div className={styles.alignButtonCsvRegister}>
                <Button
                    width="115px"
                    height="39px"
                    theme="close"
                    icon={<Download/>}
                    fontSize="14px"
                    onClick={() => handleDownloadCSV()}
                    disabled={isLoading}
                >
                    CSV
                </Button>
                <Button
                    width="115px"
                    height="39px"
                    theme="secondary"
                    fontSize="14px"
                    onClick={() => navigate("/public-tender/create")}
                    disabled={isLoading}
                >
                    Cadastrar
                </Button>
            </div>
            <div className={styles.dNone}>
                <a
                    id="download_csv"
                    rel="noopener noreferrer"
                    href={
                        process.env.REACT_APP_API_URL +
                        "/v1/public-tender/export?" +
                        serializeArray(filter) +
                        "&token=" +
                        getToken()
                    }
                    target="_blank"
                    download
                ></a>
            </div>

            <div className={styles.tableProducts}>
                {isLoading ? (
                    <div className={styles.alignCenter}>
                        <span>Carregando</span>
                        <BarLoader
                            color={"#2b6cded9"}
                            loading={isLoading}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                ) : (
                    <>
                        {data && data.data.publicTenders.length > 0 ? (
                            <table>
                                <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Banca</th>
                                    <th>Instituição</th>
                                    <th>Ano</th>
                                    <th>Edital</th>
                                    <th>Dt Cadastro</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.data.publicTenders.map(
                                    (publicTender) => (
                                        <tr key={publicTender.id}>
                                            <td>{`${institutions?.data?.institutions.find((i) => i.id === Number(publicTender.institution_id))?.name} ${publicTender.year} ${publicTender.notice_number ?? ""}`}</td>
                                            <td>{publicTender.examining_board}</td>
                                            <td>{publicTender.institution}</td>
                                            <td>{publicTender.year}</td>
                                            <td>{publicTender.notice_number}</td>
                                            <td>{publicTender.created_at}</td>
                                            <td>
                                                <button
                                                    className={
                                                        styles.editProducts
                                                    }
                                                    onClick={() =>
                                                        navigate(
                                                            `/public-tender/create/${publicTender.id}`
                                                        )
                                                    }
                                                >
                                                    <Pencil/>
                                                </button>
                                            </td>
                                            <td>
                                                <button
                                                    onClick={() => {
                                                        setId(
                                                            publicTender.id
                                                        );
                                                        setOpen(true);
                                                    }}
                                                    className={
                                                        styles.editProducts
                                                    }
                                                >
                                                    <Trash/>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                )}
                                </tbody>
                            </table>
                        ) : (
                            <div className={styles.alignCenter}>
                                <span>Nenhum registro encontrado.</span>
                            </div>
                        )}
                    </>
                )}
            </div>
            {data && (
                <Pagination
                    currentPage={data.data.currentPage}
                    lastPage={data.data.lastPage}
                    onPageChange={(payload: number) =>
                        setFilter({...filter, page: payload})
                    }
                />
            )}

            {open && id && (
                <ModalConfirm
                    propsFunction={mutateDeleteInstitution.mutate}
                    open={open}
                    setOpen={setOpen}
                    loading={mutateDeleteInstitution.isLoading}
                >
                    <span>Essa ação é irreversível!</span>
                    <span>Tem certeza que deseja deletar o concurso?</span>
                </ModalConfirm>
            )}
        </div>
    );
};

export default PublicTender;
