import React, {useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import styles from '../../../styles/PublicTender/styles.module.scss';

import {ReactComponent as Download} from '../../../assets/icons/download.svg';
import {ReactComponent as Pencil} from '../../../assets/icons/pencil.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {useNavigate} from 'react-router-dom';
import Button from "../../../components/Button/Button";
import Pagination from "../../../components/Pagination/Pagination";
import {deleteOccupation, filterInstitutions, getOccupations, serializeArray} from "../../../services/services";
import {BarLoader} from "react-spinners";
import {getToken} from "../../../services/auth";
import Select2 from "react-select";
import {queryClient} from "../../../services/queryClient";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import {toast} from "react-toastify";
import {IGetOccupations, IOccupationResponse} from "../../../ts/interfaces/Occupation/interface";
import {IGetFilterPublicTendersInstitutions} from "../../../ts/interfaces/PublicTender/interface";
import {stylesSelect} from "../../../helper/stylesSelect";

const Occupation: React.FC = () => {
    const [filter, setFilter] = useState<IGetOccupations>({
        name: '',
        page: 1,
        limit: 50,
        institution_id: '',
    });
    const [open, setOpen] = useState(false);
    const [id, setId] = useState<number | null>(null);

    const [selectFilters, setSelectFilters] = useState({
        name: '',
        institution_id: '',
    });

    const navigate = useNavigate();

    const {data, isLoading} = useQuery<IOccupationResponse>(['occupations', filter], () => getOccupations(filter), {
        staleTime: Infinity,
    });

    const handleDownloadCSV = () => {
        let link = document.getElementById('download_csv');
        link?.click();
    };

    const mutateDeleteOccupation = useMutation(() => {
        return deleteOccupation(id as number);
    }, {
        onSettled: (error: any) => {
            queryClient.invalidateQueries(['occupations', filter])
            if(error){
                toast.error(error.response.data.error)
            }
            else {
                toast.success('Deletado com sucesso');
            }
            setOpen(false)
        },
        onError: () => {
            toast.error('Erro ao deletar cargo');
        }
    })

    const {data: institutions} = useQuery<IGetFilterPublicTendersInstitutions>('filter-institutions', () => filterInstitutions())

    return (
        <div className={styles.container}>
            <h1>Cargos</h1>

            <Formik initialValues={{name: '', acronym: ''}}
                    onSubmit={(values) => {
                        setFilter({...filter, name: values.name, institution_id: selectFilters.institution_id, page: 1});
                    }}
            >
                <Form className={styles.searchProducts}>
                    <div className={styles.alignInput}>
                        <Field
                            name="name"
                            type="text"
                            placeholder="Pesquisar por nome"
                            className={styles.input}
                            disabled={isLoading}
                        />
                        <ErrorMessage name="name" component="div" className={styles.error}/>
                        <div className={styles.groupInput}>
                            {institutions && institutions?.data.institutions && (
                                <Select2
                                    styles={stylesSelect}
                                    options={institutions?.data.institutions.map((institution) => ({
                                        value: institution.id,
                                        label: institution.name,
                                    }))}
                                    onChange={(selectedOption) => {
                                        const selected = selectedOption?.value || "";
                                        setSelectFilters((prev) => ({
                                            ...prev,
                                            institution_id: selected.toString(),
                                        }));
                                    }}
                                    placeholder="Pesquise por instituição..."
                                />
                            )}
                        </div>
                    </div>

                    <div className={styles.alignButtonSearch}>
                        <Button
                            type="submit"
                            width="115px"
                            height="39px"
                            theme="primary"
                            fontSize="14px"
                            disabled={isLoading}
                        >
                            Pesquisar
                        </Button>
                    </div>
                </Form>
            </Formik>

            <div className={styles.alignButtonCsvRegister}>
                <Button
                    width="115px"
                    height="39px"
                    theme="close"
                    icon={<Download/>}
                    fontSize="14px"
                    onClick={() => handleDownloadCSV()}
                    disabled={isLoading}
                >

                    CSV
                </Button>
                <Button
                    width="115px"
                    height="39px"
                    theme="secondary"
                    fontSize="14px"
                    onClick={() => navigate('/occupation/create')}
                    disabled={isLoading}
                >
                    Cadastrar
                </Button>
            </div>
            <div className={styles.dNone}>
                <a id="download_csv" rel="noopener noreferrer"
                   href={process.env.REACT_APP_API_URL + "/v1/occupation/export?" + serializeArray(filter) + '&token=' + getToken()}
                   target="_blank" download></a>
            </div>


            <div className={styles.tableProducts}>
                {isLoading ?
                    <div className={styles.alignCenter}>
                        <span>Carregando</span>
                        <BarLoader
                            color={"#2b6cded9"}
                            loading={isLoading}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                    :
                    <>
                        {data && data.data.occupations.length > 0 ?
                            <table>
                                <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Dt Cadastro</th>
                                    <th>Concursos</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.data.occupations.map((occupation) => (
                                    <tr key={occupation.id}>
                                        <td>{occupation.name}</td>
                                        <td>{occupation.created_at}</td>
                                        <td>
                                            <button className={styles.publicTenderCount}
                                                    onClick={() => navigate(`/public-tenders?occupation=${occupation.id}`)}>
                                                {occupation.public_tender_count}
                                            </button>
                                        </td>
                                        <td>
                                            <button
                                                className={styles.editProducts}
                                                onClick={() => navigate(`/occupation/create/${occupation.id}`)}
                                            >
                                                <Pencil/>
                                            </button>
                                        </td>
                                        <td>
                                            <button
                                                onClick={() => {
                                                    setId(occupation.id);
                                                    setOpen(true);
                                                }}
                                                className={styles.editProducts}
                                            >
                                                <Trash/>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            : (
                                <div className={styles.alignCenter}><span>Nenhum registro encontrado.</span></div>
                            )}
                    </>
                }
            </div>
            {data &&
                <Pagination
                    currentPage={data.data.currentPage}
                    lastPage={data.data.lastPage}
                    onPageChange={(payload: number) => setFilter({...filter, page: payload})}
                />
            }

            {open && id &&
                <ModalConfirm propsFunction={mutateDeleteOccupation.mutate} open={open} setOpen={setOpen}
                              loading={mutateDeleteOccupation.isLoading}>
                    <span>Essa ação é irreversível!</span>
                    <span>Tem certeza que deseja deletar o cargo?</span>
                </ModalConfirm>
            }
        </div>
    );
};

export default Occupation;
