import React, {useEffect, useState} from 'react';
import styles from "../../../../styles/PublicTender/stylescreate.module.scss";
import arrow from "../../../../assets/icons/arrow-left.svg";
import {ReactComponent as Upload} from "../../../../assets/icons/upload.svg";

import Button from "../../../../components/Button/Button";
import {Form, Formik} from "formik";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import {IInstitution, IPostInstitutions} from "../../../../ts/interfaces/Institution/interface";
import {useMutation, useQuery} from "react-query";
import {filterInstitutions, getInstitution, storeInstitution} from "../../../../services/services";
import {BarLoader} from "react-spinners";
import {IPostSeo} from "../../../../ts/interfaces/Seo/interface";
import Select2 from "react-select";
import {stylesSelect} from "../../../../helper/stylesSelect";
import {IGetFilterPublicTendersInstitutions} from "../../../../ts/interfaces/PublicTender/interface";

const CreateInstitution: React.FC = () => {
    const [seoPages, setSeoPages] = useState<IPostSeo[]>([])

    const queryParams = useParams();

    const {data, isLoading} = useQuery<{
        data: IInstitution
    }>(['institution', queryParams?.id], () => getInstitution(parseInt(queryParams?.id ?? '0')), {
        staleTime: Infinity,
        enabled: !!queryParams?.id
    })

    const {data: institutions} = useQuery<IGetFilterPublicTendersInstitutions>('filter-institutions', () => filterInstitutions())


    const [params, setParams] = useState<IPostInstitutions>({
        id: data && data.data ? data.data.id : '',
        url_icon: data && data.data ? data.data.url_icon : '',
        name: data && data.data ? data.data.name : '',
        acronym: data && data.data ? data.data.acronym : '',
        seo_pages: seoPages,
        parent_institution_id: data && data.data ? data.data.parent_institution_id : "",
    })

    useEffect(() => {
        if (data && data.data) {
            setParams((prevParams) => ({
                ...prevParams,
                id: data.data.id,
                url_icon: data.data.url_icon,
                name: data.data.name,
                acronym: data.data.acronym,
                parent_institution_id: data.data.parent_institution_id,
            }))
        }
    }, [data])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> , checkbox: boolean = false) => {
        // @ts-ignore
        const { name, value, checked } = e.target;

        setParams({
            ...params,
            [name]: checkbox ? checked : value,
        });
    }

    const onSubmit = () => {
        if (!params.acronym || params.acronym.trim() === '') {
            toast.error('Preencha a sigla')
            return
        }

        if (!params.name || params.name.trim() === '') {
            toast.error('Preencha o nome')
            return
        }

        mutate.mutate(params)
    }

    const mutate = useMutation((data: IPostInstitutions) => {
        return storeInstitution(data);
    }, {
        onSettled: (response: any) => {
            if (response?.data?.success) {
                toast.success('Salvo com sucesso!')

                setTimeout(() => {
                    window.location.href = '/institutions'
                }, 800)
            } else {
                const errorMessage = response?.response?.data?.message || 'Erro ao salvar a instituição'
                toast.error(errorMessage)
            }
        },
        onError: (error) => {
            toast.error('Erro ao salvar a instituição')
        }
    })

    useEffect(() => {
        setParams({
            ...params,
            seo_pages: seoPages
        })
    }, [seoPages]);


    const [fileName, setFileName] = useState<string>("");
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const file = e.target.files?.[0];
        if (!file) return;

        if (file.type !== "image/webp") {
            toast.error("Apenas arquivos no formato WEBP são permitidos.");
            return;
        }

        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
                if (img.width !== 344 || img.height !== 200) {
                    toast.error("A imagem deve ter exatamente 344x200 pixels.");
                    return;
                }

                // Criando uma versão redimensionada (64x64)
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                if (!ctx) {
                    toast.error("Erro ao processar a imagem.");
                    return;
                }

                canvas.width = 64;
                canvas.height = 64;
                ctx.drawImage(img, 0, 0, 64, 64);

                // Convertendo a versão redimensionada para Blob
                canvas.toBlob((blob) => {
                    if (!blob) {
                        toast.error("Erro ao redimensionar a imagem.");
                        return;
                    }

                    // Criando objetos para armazenar no estado
                    const resizedFile = new File([blob], `resized_${file.name}`, { type: "image/webp" });

                    setParams((prevParams) => ({
                        ...prevParams,
                        [`${id}_large`]: file, // Imagem original
                        [`${id}`]: resizedFile, // Imagem redimensionada
                    }));

                    setFileName(file.name);
                }, "image/webp");
            };

            img.onerror = () => {
                toast.error("Erro ao carregar a imagem. Certifique-se de que o arquivo está correto.");
            };

            img.src = event.target?.result as string;
        };

        reader.readAsDataURL(file);
    };


    return (
        <>
            <Formik initialValues={{name: '', acronym: ''}}
                    onSubmit={() => onSubmit()}>
                <Form className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.title}>
                            <a href='/institutions' className={styles.btnReturn}><img src={arrow} alt="Voltar"/> Voltar</a>
                            <h1>{params.id ? 'Editar' : 'Cadastrar'} Instituição</h1>
                        </div>
                        {isLoading ?
                            <div className={styles.alignCenter}>
                                <span>Carregando</span>
                                <BarLoader
                                    color={"#2b6cded9"}
                                    loading={isLoading}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                            :
                            <>
                                <div className={styles.box}>
                                    <p className={styles.boxTitle}>Informe os dados da instituição</p>
                                    <div className={styles.groupData}>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="url_icon">
                                                    Ícone <span>(Permitido apenas arquivos webp com o tamanho de 344x200)</span>
                                                </label>
                                                <div
                                                    className={styles.fileInput}
                                                >
                                                    <input
                                                        disabled={
                                                            mutate.isLoading
                                                        }
                                                        onChange={(e) => {
                                                            handleFileChange(e, "url_icon");
                                                        }}
                                                        className={styles.input}
                                                        type="file"
                                                        name="url_icon"
                                                        id="url_icon"
                                                    />
                                                    {fileName ? (
                                                        <span>
                                                            {" "}
                                                            <Upload/>{" "}
                                                            <p>{fileName}</p>
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <Upload/> <p>Faça o
                                                            upload do ícone</p>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            {typeof params.url_icon ===
                                                "string" && (
                                                    <div
                                                        className={
                                                            styles.urlNoticeContainer
                                                        }
                                                    >
                                                        Arquivo:{" "}
                                                        <a
                                                            href={params.url_icon}
                                                            className={
                                                                styles.urlNotice
                                                            }
                                                        >
                                                            {params.url_icon}
                                                        </a>
                                                    </div>
                                                )}
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="acronym">Sigla</label>
                                                <input disabled={mutate.isLoading}
                                                       value={params.acronym}
                                                       onChange={(e) => handleChange(e)}
                                                       className={styles.input} type="text"
                                                       name="acronym"
                                                       id="acronym"/>
                                            </div>
                                        </div>
                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="name">Nome</label>
                                                <input disabled={mutate.isLoading}
                                                       className={styles.input}
                                                       value={params.name}
                                                       onChange={(e) => handleChange(e)}
                                                       type="text"
                                                       name="name"
                                                       id="name"/>
                                            </div>
                                        </div>

                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label>Instituição mãe (opcional)</label>

                                                {institutions && institutions?.data.institutions && (
                                                    <Select2
                                                        isClearable={true}
                                                        value={{
                                                            value: Number(params?.parent_institution_id),
                                                            label: institutions?.data?.institutions.find((i) => i.id === Number(params?.parent_institution_id))?.name || ""
                                                        }}
                                                        styles={stylesSelect}
                                                        options={institutions?.data.institutions.filter(item => !queryParams?.id || item.id !== parseInt(queryParams.id)).map((institution) => ({
                                                            value: institution.id,
                                                            label: institution.name,
                                                        }))}
                                                        onChange={(selectedOption) => {
                                                            const selected = selectedOption?.value || "";
                                                            setParams((prev) => ({
                                                                ...prev,
                                                                parent_institution_id: selected.toString()
                                                            }));

                                                        }}
                                                        placeholder="Pesquisar instituição"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className={styles.box}>
                                    <div className={styles.alignButtons}>
                                        <Button
                                            disabled={mutate.isLoading || (mutate.isSuccess && mutate.data?.data?.success === false)}
                                            width="175px"
                                            height="50px"
                                            type="submit">Salvar</Button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </Form>
            </Formik>
        </>
    );
};

export default CreateInstitution;
