import React, {useEffect, useState} from "react";
import styles from "../../../../styles/PublicTender/stylescreate.module.scss";
import {ReactComponent as Arrow} from "../../../../assets/icons/arrow-left.svg";

import Select from "react-select";

import {
    IGetFilterPublicTendersCareers,
    IGetFilterPublicTendersExaminingBoards,
    IGetFilterPublicTendersInstitutions,
    IGetFilterPublicTendersOccupations,
    IGetFilterPublicTendersStates,
    IPostPublicTenders,
    IPublicTender,
} from "../../../../ts/interfaces/PublicTender/interface";
import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import {
    filterCareers,
    filterExaminingBoards,
    filterInstitutions,
    filterOccupations,
    getPublicTender,
    getStates,
    storePublicTender,
} from "../../../../services/services";
import {toast} from "react-toastify";
import {Form, Formik} from "formik";
import {BarLoader} from "react-spinners";
import Button from "../../../../components/Button/Button";
import {ReactComponent as Upload} from "../../../../assets/icons/upload.svg";
import {IPostSeo} from "../../../../ts/interfaces/Seo/interface";
import {PublicTenderStatus} from "../../../../Enums/PublicTenderStatus";
import Occupation from "../../../../components/Occupation/Occupation"


const CreatePublicTender: React.FC = () => {
    const [seoPages, setSeoPages] = useState<IPostSeo[]>([]);

    const [fileName, setFileName] = useState<{ url_icon: string; url_notice: string }>({
        url_icon: "",
        url_notice: "",
    });

    const stylesSelect = {
        control: (styles: any) => ({
            ...styles,
            width: "100%",
            height: "37px",
        }),
        indicatorsContainer: (styles: any) => ({
            ...styles,
            height: "37px",
        }),
        valueContainer: (styles: any) => ({
            ...styles,
            height: "37px",
            display: "flex",
            alignItems: "center",
            padding: "0px 8px",
            "overflow-y": "auto"
        }),
        dropdownIndicator: (styles: any) => ({
            ...styles,
            padding: "4px",
        }),
        clearIndicator: (styles: any) => ({
            ...styles,
            padding: "4px",
        }),
    };

    const queryParams = useParams();

    const {data, isLoading} = useQuery<{
        data: IPublicTender;
        status: number
    }>(
        ["public-tenders", queryParams?.id],
        () => getPublicTender(parseInt(queryParams?.id ?? "0")),
        {

            staleTime: Infinity,
            enabled: !!queryParams?.id,
        }
    );

    if (!isLoading && queryParams?.id && data?.status !== 200) {

        window.location.href = '/public-tenders';
    }
    const {data: examiningBoards} =
        useQuery<IGetFilterPublicTendersExaminingBoards>(
            "filter-examining-boards",
            () => filterExaminingBoards()
        );


    const {data: institutions} =
        useQuery<IGetFilterPublicTendersInstitutions>(
            "filter-institutions",
            () => filterInstitutions()
        );

    const {data: states} = useQuery<IGetFilterPublicTendersStates>(
        "filter-states",
        () => getStates({state: ""})
    );

    const {data: occupations} = useQuery<IGetFilterPublicTendersOccupations>(
        "filter-occupations",
        () => filterOccupations()
    );

    const {data: careers} = useQuery<IGetFilterPublicTendersCareers>(
        "filter-careers",
        () => filterCareers()
    );

    const [params, setParams] = useState<IPostPublicTenders>({
        id: 0,
        name: "",
        url_notice: null,
        url_icon: null,
        notice_number: "",
        year: 0,
        status: "",
        institution_id: 0,
        examining_board_id: 0,
        registration_date_start: "",
        registration_date_end: "",
        test_date: "",
        states: [],
        occupations: [],
        seo_pages: seoPages,
    });

    useEffect(() => {
        if (data && data.data) {
            let copy = params
            copy = {
                ...copy,
                id: data.data.id,
                name: data.data.name,
                url_notice: data.data.url_notice,
                url_icon: data.data.url_icon,
                year: data.data.year,
                notice_number: data.data.notice_number,
                institution_id: data.data.institution_id,
                examining_board_id: data.data.examining_board_id,
                registration_date_start: data.data.registration_date_start,
                registration_date_end: data.data.registration_date_end,
                test_date: data.data.test_date,
                states: data.data.states.map((state) => state.id),
                status: data.data.status
            }

            if (params.occupations.length === 0) {
                copy.occupations = data.data.occupations.map(
                    (occupation) => ({
                        id: occupation.id,
                        name: occupation.name,
                        url_exam: occupation.pivot?.url_exam,
                        url_answer_key: occupation.pivot?.url_answer_key,
                        occupation_notice: occupation.pivot?.occupation_notice,
                        vacancies: occupation.pivot?.vacancies,
                        reserve_register: occupation.pivot?.reserve_register,
                        salary_type: occupation.pivot?.salary_type ?? 'monthly',
                        salary: occupation.pivot?.salary,
                        career_id: occupation.pivot?.career_id,
                        taf: occupation.pivot?.taf,
                        discursive_writing: occupation.pivot?.discursive_writing,
                        proof_of_titles: occupation.pivot?.proof_of_titles,
                        level: occupation.pivot?.level,
                        specialty: occupation.pivot?.specialty,
                    })
                );
            }

            setParams(copy);
        }
    }, [data]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setParams({...params, [e.target.name]: e.target.value});
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const file = e.target.files?.[0];
        if (file) {
            setParams((prevParams) => ({
                ...prevParams,
                [id]: file,
            }));

            if (id === "url_icon") {
                setFileName({...fileName, url_icon: file.name});
            } else if (id === "url_notice") {
                setFileName({...fileName, url_notice: file.name});
            }
        }
    };

    const onSubmit = () => {
        if (!params.year || params.year === 0) {
            toast.error("Preencha o ano");
            return;
        }

        if (!params.name || params.name.trim() === '') {
            toast.error("Preencha o nome");
            return;
        }

        if (!params.institution_id || params.institution_id === 0) {
            toast.error("Selecione a instituição");
            return;
        }

        const formData = new FormData();

        formData.append("id", params.id.toString());
        formData.append("name", params.name || "");

        if (params.url_notice instanceof File) {
            formData.append("url_notice", params.url_notice);
        }

        if (params.url_icon instanceof File) {
            formData.append("url_icon", params.url_icon);
        }

        formData.append("year", params.year ? params.year.toString() : '');
        formData.append("notice_number", params.notice_number ? params.notice_number.toString() : '');
        formData.append("institution_id", params.institution_id.toString());
        formData.append(
            "examining_board_id",
            params.examining_board_id ? params.examining_board_id.toString() : ""
        );
        formData.append(
            "registration_date_start",
            params.registration_date_start ? params.registration_date_start : ""
        );

        formData.append("registration_date_end", params.registration_date_end ? params.registration_date_end : "");
        formData.append("test_date", params.test_date ? params.test_date : "");
        formData.append("status", params.status ? params.status : "");

        for (let i = 0; i < params.states.length; i++) {
            formData.append("states[]", params.states[i].toString());
        }

        formData.append("seo_pages", JSON.stringify(seoPages));

        for (let i = 0; i < params.occupations.length; i++) {
            const {
                id,
                name,
                url_exam,
                url_answer_key,
                occupation_notice,
                vacancies,
                reserve_register,
                salary_type,
                salary,
                career_id,
                taf,
                discursive_writing,
                proof_of_titles,
                level,
                specialty
            } = params.occupations[i];

            let position = i + 1;
            formData.append(`occupation_id_${position}`, id.toString());
            formData.append(`occupation_name_${position}`, name);
            if (url_exam instanceof File) {
                formData.append(`occupation_url_exam_${position}`, url_exam);
            } else if (url_exam !== null && typeof url_exam === 'string') {
                formData.append(`occupation_url_exam_${position}`, url_exam);
            }
            if (url_answer_key instanceof File) {
                formData.append(`occupation_url_answer_key_${position}`, url_answer_key);
            } else if (url_answer_key !== null && typeof url_answer_key === 'string') {
                formData.append(`occupation_url_answer_key_${position}`, url_answer_key);
            }
            formData.append(`occupation_occupation_notice_${position}`, occupation_notice ?? "");
            formData.append(`occupation_vacancies_${position}`, vacancies?.toString() ?? "");
            formData.append(`occupation_reserve_register_${position}`, reserve_register?.toString() ?? "");
            formData.append(`occupation_salary_type_${position}`, salary_type?.toString() ?? "");
            formData.append(`occupation_salary_${position}`, salary?.toString() ?? "");
            formData.append(`occupation_career_id_${position}`, career_id?.toString() ?? "");
            formData.append(`occupation_taf_${position}`, taf ? "1" : "0");
            formData.append(`occupation_discursive_writing_${position}`, discursive_writing ? "1" : "0");
            formData.append(`occupation_proof_of_titles_${position}`, proof_of_titles ? "1" : "0");
            formData.append(`occupation_level_${position}`, level?.toString() ?? "");
            formData.append(`occupation_specialty_${position}`, specialty?.toString() ?? "");

        }
        mutate.mutate(formData);
    };

    const mutate = useMutation(
        (data: FormData) => {
            return storePublicTender(data);
        },
        {
            onSettled: (response) => {
                if (response?.data?.success) {
                    toast.success("Salvo com sucesso!");

                    setTimeout(() => {
                        window.location.href = "/public-tenders";
                    }, 800);
                } else {
                    toast.error("Erro ao salvar o concurso");
                }
            },
            onError: (error) => {
                toast.error("Erro ao salvar o concurso");
            },
        }
    );

    const getYears = () => {
        let years = [];
        let actualYear = new Date().getFullYear();
        for (let i = actualYear; i >= actualYear - 20; i--) {
            years.push(i);
        }

        return years;
    };

    useEffect(() => {
        setParams({
            ...params,
            seo_pages: seoPages,
        });
    }, [seoPages]);

    const handleOccupationsChange = (selectedOptions: any) => {
        setParams((prevParams) => {

            let copy = prevParams.occupations.slice();
            copy.push({
                id: selectedOptions.value,
                name: selectedOptions.label,
                vacancies: 0,
                reserve_register: "",
                specialty: "",
                salary_type: 'monthly',
                salary: 0,
                career_id: 0,
                taf: false,
                discursive_writing: false,
                proof_of_titles: false,
                level: ''
            })

            return {
                ...prevParams,
                occupations: copy
            };
        });
    };


    return (
        <>
            <Formik
                initialValues={{name: "", acronym: ""}}
                onSubmit={() => onSubmit()}
            >
                <Form className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.title}>
                            <a
                                href="/public-tenders"
                                className={styles.btnReturn}
                            >
                                <Arrow/> Voltar
                            </a>
                            <h1>
                                {params.id ? "Editar" : "Cadastrar"} Concurso
                            </h1>
                        </div>
                        {isLoading ? (
                            <div className={styles.alignCenter}>
                                <span>Carregando</span>
                                <BarLoader
                                    color={"#2b6cded9"}
                                    loading={isLoading}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                        ) : (
                            <>
                                <div className={styles.box}>
                                    <p className={styles.boxTitle}>
                                        Informe os dados do concurso
                                    </p>

                                    <div className={styles.groupData}>

                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="institution_id">
                                                    Instituição
                                                </label>
                                                <Select
                                                    isClearable={true}

                                                    defaultValue={{
                                                        value: data?.data?.institution_id,
                                                        label: institutions?.data?.institutions.find(
                                                            (institution) =>
                                                                institution.id ===
                                                                data?.data?.institution_id
                                                        )?.name,
                                                    }}
                                                    name="institution_id"
                                                    styles={stylesSelect}
                                                    options={institutions?.data?.institutions.map(
                                                        (institution) => ({
                                                            value: institution.id,
                                                            label: institution.name,
                                                        })
                                                    )}
                                                    onChange={(
                                                        selectedOption
                                                    ) => {
                                                        setParams({
                                                            ...params,
                                                            institution_id:
                                                                selectedOption?.value ??
                                                                0,
                                                            name: selectedOption?.label ?? "",
                                                        });
                                                    }}
                                                    placeholder="Selecione a instituição..."
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="examining_board_id">
                                                    Banca Examinadora
                                                </label>
                                                <Select
                                                    isClearable={true}
                                                    name="examining_board_id"
                                                    styles={stylesSelect}
                                                    defaultValue={{
                                                        value: data?.data?.examining_board_id,
                                                        label: examiningBoards?.data?.examiningBoards.find(
                                                            (examiningBoard) =>
                                                                examiningBoard.id ===
                                                                data?.data?.examining_board_id
                                                        )?.name,
                                                    }}
                                                    options={examiningBoards?.data?.examiningBoards.map(
                                                        (examiningBoard) => ({
                                                            value: examiningBoard.id,
                                                            label: examiningBoard.name,
                                                        })
                                                    )}
                                                    onChange={(
                                                        selectedOption
                                                    ) => {
                                                        setParams({
                                                            ...params,
                                                            examining_board_id:
                                                                selectedOption?.value ??
                                                                0,
                                                        });
                                                    }}
                                                    placeholder="Selecione a banca..."
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="name">
                                                    Título
                                                </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={params?.name ?? ""}
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="year">
                                                    Ano
                                                </label>
                                                <Select
                                                    isClearable={true}
                                                    defaultValue={{
                                                        value: data?.data?.year,
                                                        label: data?.data?.year ? data?.data.year.toString() : '',
                                                    }}
                                                    name="year"
                                                    styles={stylesSelect}
                                                    options={getYears().map(
                                                        (year) => ({
                                                            value: year,
                                                            label: year.toString(),
                                                        })
                                                    )}
                                                    onChange={(
                                                        selectedOption
                                                    ) => {
                                                        setParams({
                                                            ...params,
                                                            year:
                                                                selectedOption?.value ??
                                                                0,
                                                        });
                                                    }}
                                                    placeholder="Selecione o ano..."
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="registration_date_start">
                                                    Data de inscrição
                                                </label>
                                                <input
                                                    disabled={mutate.isLoading}
                                                    className={styles.input}
                                                    value={
                                                        params.registration_date_start ?? ""
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    type="date"
                                                    name="registration_date_start"
                                                    id="registration_date_start"
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="registration_date_end">
                                                    Data de encerramento
                                                </label>
                                                <input
                                                    disabled={mutate.isLoading}
                                                    className={styles.input}
                                                    value={
                                                        params.registration_date_end ?? ""
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    type="date"
                                                    name="registration_date_end"
                                                    id="registration_date_end"
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="status">
                                                    Status
                                                </label>
                                                <Select
                                                    isClearable={true}
                                                    defaultValue={{
                                                        value: data?.data?.status,
                                                        label: data?.data?.status_label,
                                                    }}
                                                    name="status"
                                                    styles={stylesSelect}
                                                    options={[
                                                        {
                                                            value: PublicTenderStatus.ANNOUNCED,
                                                            label: "Anunciado"
                                                        },
                                                        {
                                                            value: PublicTenderStatus.AUTHORIZED,
                                                            label: "Autorizado"
                                                        },
                                                        {
                                                            value: PublicTenderStatus.BANKHIRED,
                                                            label: "Banca contratada"
                                                        },
                                                        {
                                                            value: PublicTenderStatus.BANKDEFINED,
                                                            label: "Banca definida"
                                                        },
                                                        {
                                                            value: PublicTenderStatus.COMMISSIONFORMED,
                                                            label: "Comissão formada"
                                                        },
                                                        {
                                                            value: PublicTenderStatus.PUBLISHEDNOTICE,
                                                            label: "Edital publicado"
                                                        },
                                                        {
                                                            value: PublicTenderStatus.INPROGRESS,
                                                            label: "Em andamento"
                                                        },
                                                        {
                                                            value: PublicTenderStatus.FINALIZED,
                                                            label: "Encerrado/Edital Válido"
                                                        },
                                                        {
                                                            value: PublicTenderStatus.EXPECTED,
                                                            label: "Previsto"
                                                        },
                                                        {
                                                            value: PublicTenderStatus.BASICPROJECTPUBLISHED,
                                                            label: "Projeto básico divulgado"
                                                        },
                                                        {
                                                            value: PublicTenderStatus.NOCURRENTNOTICE,
                                                            label: "Sem edital vigente"
                                                        },
                                                        {
                                                            value: PublicTenderStatus.REQUESTED,
                                                            label: "Solicitado/Em estudos"
                                                        },
                                                    ]}
                                                    onChange={(
                                                        selectedOption
                                                    ) => {
                                                        setParams({
                                                            ...params,
                                                            status:
                                                                selectedOption?.value ??
                                                                "",
                                                        });
                                                    }}
                                                    placeholder="Selecione o nível..."
                                                />
                                            </div>
                                        </div>


                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="url_notice">
                                                    Edital
                                                </label>
                                                <div
                                                    className={styles.fileInput}
                                                >
                                                    <input
                                                        disabled={
                                                            mutate.isLoading
                                                        }
                                                        onChange={(e) => {
                                                            handleFileChange(e, "url_notice");
                                                        }}
                                                        className={styles.input}
                                                        type="file"
                                                        name="url_notice"
                                                        id="url_notice"
                                                        accept="application/pdf"
                                                    />
                                                    {fileName.url_notice ? (
                                                        <span>
                                                            {" "}
                                                            <Upload/>{" "}
                                                            <p>{fileName.url_notice}</p>
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <Upload/> <p>Faça o
                                                            upload do edital</p>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            {typeof params.url_notice ===
                                                "string" && (
                                                    <div
                                                        className={
                                                            styles.urlNoticeContainer
                                                        }
                                                    >
                                                        Arquivo:{" "}
                                                        <a
                                                            href={params.url_notice}
                                                            className={
                                                                styles.urlNotice
                                                            }
                                                        >
                                                            {params.url_notice}
                                                        </a>
                                                    </div>
                                                )}
                                        </div>

                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="notice_number">
                                                    Número Edital
                                                </label>
                                                <input
                                                    disabled={mutate.isLoading}
                                                    value={params.notice_number ?? ""}
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    className={styles.input}
                                                    type="text"
                                                    name="notice_number"
                                                    id="notice_number"
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.alignInput}>
                                            <div className={styles.groupInput}>
                                                <label htmlFor="test_date">
                                                    Data da prova
                                                </label>
                                                <input
                                                    disabled={mutate.isLoading}
                                                    className={styles.input}
                                                    value={params.test_date ?? ""}
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                    type="date"
                                                    name="test_date"
                                                    id="test_date"
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.groupInput}>
                                            <label htmlFor="">Estados</label>
                                            <Select
                                                isClearable={true}
                                                defaultValue={data?.data?.states.map(
                                                    (state) => ({
                                                        value: state.id,
                                                        label: state.name  + (state.name !== state.acronym ? ' - ' + state.acronym : ''),
                                                    })
                                                )}
                                                styles={stylesSelect}
                                                options={states?.data?.states.map(
                                                    (state) => ({
                                                        value: state.id,
                                                        label: state.name + (state.name !== state.acronym ? ' - ' + state.acronym : ''),
                                                    })
                                                )}
                                                placeholder="Selecione os estados..."
                                                onChange={(selectedOptions) => {
                                                    setParams({
                                                        ...params,
                                                        states: selectedOptions.map(
                                                            (option) =>
                                                                option.value
                                                        ),
                                                    });
                                                }}
                                                isMulti
                                            />
                                        </div>
                                        <div className={styles.groupInput}>
                                            <label htmlFor="">Cargos</label>
                                            <Select
                                                defaultValue={null}
                                                styles={stylesSelect}
                                                options={occupations?.data?.occupations.map(
                                                    (occupation) => ({
                                                        value: occupation.id,
                                                        label: occupation.name,
                                                    })
                                                )}
                                                onChange={handleOccupationsChange}
                                                placeholder="Selecione os cargos..."
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={`${styles.box} ${styles.occupations}`}>
                                    <p className={styles.boxTitle}>
                                        Informe as provas por cargo - Total: {params.occupations.length}
                                    </p>
                                    <div className={`${styles.groupData} ${styles.occupations}`}>
                                        {params.occupations.map((occupation, index) => (
                                            <Occupation name={occupation.name} setParams={setParams} params={params}
                                                        index={index} careers={careers}/>
                                        ))

                                        }
                                    </div>
                                </div>
                                <div className={styles.box}>
                                    <div className={styles.alignButtons}>
                                        <Button
                                            disabled={mutate.isLoading}
                                            width="175px"
                                            height="50px"
                                            type="submit"
                                        >
                                            Salvar
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </Form>
            </Formik>
        </>
    );
};

export default CreatePublicTender;
