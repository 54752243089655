import React, {useState} from "react";
import styles from "./styles.module.scss";
import {ReactComponent as Upload} from "../../assets/icons/upload.svg";
import Select from "react-select";
import {IGetFilterPublicTendersCareers, IPostPublicTenders} from "../../ts/interfaces/PublicTender/interface";

interface OccupationProps {
    name: string
    setParams: (data: IPostPublicTenders) => void;
    params: IPostPublicTenders;
    careers: IGetFilterPublicTendersCareers | undefined;
    index: number;

}

const stylesSelect = {
    control: (styles: any) => ({
        ...styles,
        width: "100%",
        height: "37px",
    }),
    indicatorsContainer: (styles: any) => ({
        ...styles,
        height: "37px",
    }),
    valueContainer: (styles: any) => ({
        ...styles,
        height: "37px",
        display: "flex",
        alignItems: "center",
        padding: "0px 8px",
    }),
    dropdownIndicator: (styles: any) => ({
        ...styles,
        padding: "4px",
    }),
    clearIndicator: (styles: any) => ({
        ...styles,
        padding: "4px",
    }),
};

const Occupation: React.FC<OccupationProps> = ({name, setParams, params, index, careers}) => {

    const [fileName, setFileName] = useState<{ url_exam: string; url_answer_key: string }>({
        url_exam: "",
        url_answer_key: "",
    });

    const handleFileChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        field: 'url_exam' | 'url_answer_key'
    ) => {
        const file = e.target.files?.[0];
        setParams({
            ...params,
            occupations: params.occupations?.map((o, i) =>
                i === index ? {...o, [field]: file} : o
            )
        });
        setFileName({...fileName, [field]: file?.name})
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setParams({
            ...params,
            occupations: params.occupations.map((o, i) =>
                i === index ? {...o, [e.target.name]: e.target.value} : o
            )
        });
    };

    const removeOccupation = () => {
        let copy = params.occupations.slice()
        if (copy[index]) {
            copy.splice(index, 1);
        }
        setParams({
            ...params,
            occupations: copy
        })
    }


    return (
        <section>
            <div className={styles.groupData}>
                <div className={styles.course}>
                    <div className={styles.header}>
                        <p className={styles.examNumber}>Cargo: {name}</p>
                        <span className={styles.close} onClick={() => removeOccupation()}>&times;</span>
                    </div>
                    <div className={styles.groupInput}>
                        <label>Prova</label>
                        <div className={styles.fileInput}>
                            <input
                                type="file"
                                className={styles.input}
                                onChange={(e) => handleFileChange(e, 'url_exam')}
                                accept="application/pdf"
                            />

                            {fileName?.url_exam ? (
                                <span>
                                        {" "}
                                    <Upload/> <p>{fileName?.url_exam}</p>
                                    </span>
                            ) : (
                                <span>
                                        <Upload/> <p>Faça o upload da prova</p>
                                    </span>
                            )}

                        </div>
                    </div>
                    {typeof params.occupations[index].url_exam ===
                        "string" && (
                            <div
                                className={
                                    styles.urlNoticeContainer
                                }
                            >
                                Arquivo:{" "}
                                <a
                                    href={params.occupations[index]?.url_exam?.toString()}
                                    className={
                                        styles.urlNotice
                                    }
                                >
                                    {params.occupations[index]?.url_exam?.toString()}
                                </a>
                            </div>
                        )}
                    <div className={styles.groupInput}>
                        <label>Gabarito</label>
                        <div className={styles.fileInput}>
                            <input
                                type="file"
                                className={styles.input}
                                onChange={(e) => handleFileChange(e, 'url_answer_key')}
                                accept="application/pdf"

                            />
                            {fileName?.url_answer_key ? (
                                <span>
                                        {" "}
                                    <Upload/> <p>{fileName.url_answer_key}</p>
                                    </span>
                            ) : (
                                <span>
                                        <Upload/> <p>Faça o upload do gabarito</p>
                                    </span>
                            )}
                        </div>
                    </div>
                    {typeof params.occupations[index].url_answer_key ===
                        "string" && (
                            <div
                                className={
                                    styles.urlNoticeContainer
                                }
                            >
                                Arquivo:{" "}
                                <a
                                    href={params.occupations[index]?.url_answer_key?.toString()}
                                    className={
                                        styles.urlNotice
                                    }
                                >
                                    {params.occupations[index]?.url_answer_key?.toString()}
                                </a>
                            </div>
                        )}
                    <div className={styles.groupInput}>
                        <label>Especialidade</label>
                        <input
                            type="text"
                            name="specialty"
                            value={params.occupations[index].specialty}
                            onChange={handleChange}
                        />
                    </div>
                    <div className={styles.groupInput}>
                        <label>Cargo conforme edital</label>
                        <input
                            type="text"
                            name="occupation_notice"
                            value={params.occupations[index].occupation_notice}
                            onChange={handleChange}
                        />
                    </div>
                    <div className={styles.groupInput}>
                        <label>Vagas diretas</label>
                        <input
                            type="number"
                            name="vacancies"
                            value={params.occupations[index].vacancies}
                            onChange={handleChange}

                        />
                    </div>
                    <div className={styles.groupInput}>
                        <label>Cadastro reserva</label>
                        <input
                            type="text"
                            name="reserve_register"
                            value={params.occupations[index].reserve_register}
                            onChange={handleChange}
                            required={true}
                        />
                    </div>
                    <div className={styles.groupInput}>
                        <label htmlFor="level">Tipo de Salário</label>
                        <Select
                            isClearable={true}
                            defaultValue={{
                                value: params.occupations[index].salary_type ?? 'monthly',
                                label: (!params.occupations[index].salary_type || params.occupations[index].salary_type === "monthly") ? "Mensal" : "Horista"
                            }}
                            name="salary_type"
                            styles={stylesSelect}
                            options={[
                                {value: "monthly", label: "Mensal"},
                                {value: "hourly", label: "Horista"},
                            ]}
                            onChange={(
                                selectedOption
                            ) => {
                                setParams({
                                    ...params,
                                    occupations: params.occupations.map((o, i) =>
                                        i === index ? {...o, salary_type: selectedOption?.value} : o
                                    ),
                                });
                            }}
                            placeholder="Selecione o Tipo de Salário"
                        />
                    </div>
                    <div className={styles.groupInput}>
                        <label>Salário</label>
                        <input
                            type="number"
                            name="salary"
                            value={params.occupations[index].salary}
                            onChange={handleChange}
                            required={true}
                        />
                    </div>
                    <div className={styles.groupInput}>
                        <label htmlFor="">Carreiras</label>
                        {careers && careers.data && careers.data.careers && (
                            <Select
                                isClearable={true}
                                defaultValue={{
                                    value: params.occupations[index].career_id,
                                    label: careers?.data?.careers.find((c) => c.id === params.occupations[index].career_id)?.name || ""
                                }}
                                styles={stylesSelect}
                                options={careers?.data.careers.map(
                                    (career) => ({
                                        value: career.id,
                                        label: career.name,
                                    })
                                )}
                                onChange={(selectedOption) => {
                                    setParams({
                                        ...params,
                                        occupations:
                                            params.occupations.map((o, i) =>
                                                i === index ? {...o, career_id: selectedOption?.value} : o
                                            )
                                    });
                                }}
                                placeholder="Selecione a carreira..."
                            />
                        )}
                    </div>
                    <div className={styles.groupInput}>
                        <label htmlFor="level">Nível</label>
                        <Select
                            isClearable={true}
                            defaultValue={{
                                value: params.occupations[index].level,
                                label: params.occupations[index].level || "Selecione o nível"
                            }}
                            name="level"
                            styles={stylesSelect}
                            options={[
                                {value: "alfabetizado", label: "Alfabetizado"},
                                {value: "fundamental_incompleto", label: "fundamental incompleto"},
                                {value: "fundamental", label: "Fundamental"},
                                {value: "médio", label: "Médio"},
                                {value: "superior", label: "Superior"},
                                {value: "técnico", label: "Técnico"},
                            ]}
                            onChange={(
                                selectedOption
                            ) => {
                                setParams({
                                    ...params,
                                    occupations: params.occupations.map((o, i) =>
                                        i === index ? {...o, level: selectedOption?.value} : o
                                    ),
                                });
                            }}
                            placeholder="Selecione o nível"
                        />
                    </div>

                    <div className={styles.alignCheckbox}>
                        <div className={styles.alignInput}>
                            <div
                                className={
                                    styles.groupInput
                                }
                            >
                                <label htmlFor="taf">
                                    Teste de Aptidão Física
                                </label>
                                <input

                                    className={styles.input}
                                    type="checkbox"
                                    checked={params.occupations[index].taf}
                                    onChange={(e) =>
                                        setParams({
                                            ...params,
                                            occupations: params.occupations.map((o, i) =>
                                                i === index ? {...o, taf: e.target.checked} : o
                                            )
                                        })}
                                    name="taf"
                                    id="taf"
                                />
                            </div>
                        </div>
                        <div className={styles.alignInput}>
                            <div
                                className={
                                    styles.groupInput
                                }
                            >
                                <label htmlFor="discursive_writing">
                                    Redação Discursiva
                                </label>
                                <input

                                    className={styles.input}
                                    type="checkbox"
                                    checked={
                                        params.occupations[index].discursive_writing
                                    }
                                    onChange={(e) =>
                                        setParams({
                                            ...params,
                                            occupations: params.occupations.map((o, i) =>
                                                i === index ? {...o, discursive_writing: e.target.checked} : o
                                            )
                                        })}
                                    name="discursive_writing"
                                    id="discursive_writing"
                                />
                            </div>
                        </div>
                        <div className={styles.alignInput}>
                            <div
                                className={
                                    styles.groupInput
                                }
                            >
                                <label htmlFor="proof_of_titles">
                                    Prova de títulos
                                </label>
                                <input

                                    className={styles.input}
                                    type="checkbox"
                                    checked={
                                        params.occupations[index].proof_of_titles
                                    }
                                    onChange={(e) =>
                                        setParams({
                                            ...params,
                                            occupations: params.occupations.map((o, i) =>
                                                i === index ? {...o, proof_of_titles: e.target.checked} : o
                                            )
                                        })}
                                    name="proof_of_titles"
                                    id="proof_of_titles"
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default Occupation;
